import { DefaultTheme } from 'styled-components';

export const baseTheme: DefaultTheme = {
  borderRadius: '6px',
  colors: {
    primaryColor: '#262626',
    secondaryColor: '#ffffff99',
    backgroundColor: '#000',
    secondaryBackgroundColor: '#8bc0ea',
    primaryTextColor: '#fff',
    secondaryTextColor: '#000',
    trivialTextColor: '#a0c8ff',
    notificationTextColor: '#c078ea',
    error: '#f16704',
  },
  buttons: {
    main: 'rgb(195, 206, 217)',
    focus: 'rgb(155, 173, 191)',
    hover: 'rgb(155, 173, 191)',
    disabled: 'rgb(155, 173, 191)',
  },
};
