import { useMemo } from 'react';
import moment from 'moment';

import { AggregatedShiftsType, ShiftType } from 'types';
import { getDayNameOfDateEn } from 'utils';

const initialAggregatedShifts: AggregatedShiftsType = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
};

export const useAggregatedShifts = (shifts: ShiftType[]) => {
  return useMemo(() => {
    return shifts
      .filter(shift => shift.isReservable)
      .reduce((shiftsObject, currentShift) => {
        const dayShift = {
          startsAt: currentShift.startsAt,
          endsAt: currentShift.endsAt,
        };

        const dayKeys = Object.keys(initialAggregatedShifts);

        dayKeys.forEach(dayKey => {
          // @ts-ignore - day keys are correct
          if (currentShift[dayKey]) {
            shiftsObject[dayKey].push(dayShift);
          }
        });

        return shiftsObject;
      }, initialAggregatedShifts);
  }, [shifts]);
};

export const getTimeRangeForTimeSlot = ({
  unixTimestamp,
  maxLeadTime,
  minLeadTime,
  turnTime,
  timeRangeBufferInHour = 2,
}: {
  unixTimestamp: number;
  maxLeadTime: number;
  minLeadTime: number;
  turnTime: number;
  timeRangeBufferInHour?: number;
}): {
  earliestStartingTime: number;
  latestStartingTime: number;
} => {
  const additionalBufferForCalculations = 60;

  return {
    earliestStartingTime: Math.max(
      moment
        .unix(unixTimestamp)
        .subtract(timeRangeBufferInHour, 'hours')
        .unix(),
      moment()
        .add(minLeadTime, 'seconds')
        .add(additionalBufferForCalculations, 'seconds')
        .unix()
    ),
    latestStartingTime: Math.min(
      moment.unix(unixTimestamp).add(timeRangeBufferInHour, 'hours').unix(),
      moment().add(maxLeadTime, 'seconds').subtract(turnTime, 'seconds').unix()
    ),
  };
};

export const getApplicableShiftWithFee = (
  reservationTimestamp: number,
  guestCount: number,
  shifts: ShiftType[]
) => {
  const reservationWeekday = getDayNameOfDateEn(
    moment.unix(reservationTimestamp)
  );
  const filteredAndSortedWeekdayShifts = shifts
    .filter(shift => shift[reservationWeekday])
    .sort((a, b) => a.startsAt - b.startsAt);

  // reservation timestamp at midnight
  const momentMidnight = moment.unix(reservationTimestamp).startOf('day');
  const reservationSecondsFromMidnight = moment
    .unix(reservationTimestamp)
    .diff(momentMidnight, 'seconds');

  const applicableShiftsWithFee = filteredAndSortedWeekdayShifts.filter(
    shift => {
      return (
        shift.startsAt <= reservationSecondsFromMidnight &&
        shift.endsAt > reservationSecondsFromMidnight &&
        shift.minimumCancellationFeeGroupSize <= guestCount &&
        shift.cancellationFeePerPerson > 0
      );
    }
  );

  if (applicableShiftsWithFee.length > 0) {
    return applicableShiftsWithFee.reduce((shiftWithMaxFee, currentShift) => {
      if (
        currentShift.cancellationFeePerPerson >
        shiftWithMaxFee.cancellationFeePerPerson
      ) {
        return currentShift;
      }
      return shiftWithMaxFee;
    });
  }

  return null;
};
