import { useIntl } from 'react-intl';

import { getFormattedDate, getFormattedTime } from 'utils';

import {
  StyledInfoItem,
  StyledTerm,
  StyledValue,
  StyledWrapper,
} from './ReserveSlotInfo.styled';

type PropertiesType = {
  guestCount: number;
  locationGroupName: string;
  reservationTimestamp: number;
};
export const ReserveSlotInfo = ({
  guestCount,
  locationGroupName,
  reservationTimestamp,
}: PropertiesType) => {
  const intl = useIntl();

  return (
    <StyledWrapper>
      <StyledInfoItem>
        <StyledTerm>
          {intl.formatMessage({
            id: 'reservations.reserveSuccess.locationName',
          })}
          :
        </StyledTerm>
        <StyledValue>{locationGroupName}</StyledValue>
      </StyledInfoItem>
      <StyledInfoItem>
        <StyledTerm>
          {intl.formatMessage({
            id: 'reservations.reserveSlot.overview.date',
          })}
          :
        </StyledTerm>
        <StyledValue>{getFormattedDate(reservationTimestamp)}</StyledValue>
      </StyledInfoItem>
      <StyledInfoItem>
        <StyledTerm>
          {intl.formatMessage({
            id: 'reservations.reserveSlot.overview.time',
          })}
          :
        </StyledTerm>
        <StyledValue>{getFormattedTime(reservationTimestamp)}</StyledValue>
      </StyledInfoItem>
      <StyledInfoItem>
        <StyledTerm>
          {intl.formatMessage({
            id: 'reservations.reserveSlot.overview.guestCount',
          })}
          :
        </StyledTerm>
        <StyledValue>{guestCount}</StyledValue>
      </StyledInfoItem>
    </StyledWrapper>
  );
};
