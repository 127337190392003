export const PROD_BASE_PATH = 'https://app.luca-app.de';
const PREPROD_BASE_PATH = 'https://app-preprod.luca-app.de';
const DEV_BASE_PATH = 'https://app-dev.luca-app.de';
const RELEASE_BASE_PATH = 'https://app-release.luca-app.de';
const P1_BASE_PATH = 'https://app-p1.luca-app.de';
const P2_BASE_PATH = 'https://app-p2.luca-app.de';
const P3_BASE_PATH = 'https://app-p3.luca-app.de';
const P4_BASE_PATH = 'https://app-p4.luca-app.de';
const P5_BASE_PATH = 'https://app-p5.luca-app.de';
const P6_BASE_PATH = 'https://app-p6.luca-app.de';
const LOCALHOST_BASE_PATH = 'https://localhost';

export const environmentPathMap: { [key: string]: string } = {
  prod: PROD_BASE_PATH,
  preprod: PREPROD_BASE_PATH,
  dev: DEV_BASE_PATH,
  release: RELEASE_BASE_PATH,
  p1: P1_BASE_PATH,
  p2: P2_BASE_PATH,
  p3: P3_BASE_PATH,
  p4: P4_BASE_PATH,
  p5: P5_BASE_PATH,
  p6: P6_BASE_PATH,
  local: LOCALHOST_BASE_PATH,
};

export const QUERY_KEYS = {
  LOCATION_GROUP: 'LOCATION_GROUP',
  THEME: 'THEME',
};
