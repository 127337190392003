import { useMemo } from 'react';
import { useMountEffect, useUnmountEffect } from '@react-hookz/web';

export const usePortal = () => {
  const widgetContainer = useMemo(() => {
    return document.createElement('div');
  }, []);

  useMountEffect(() => {
    document.body.append(widgetContainer);
  });

  useUnmountEffect(() => {
    widgetContainer.remove();
  });

  return widgetContainer;
};
