import { useMemo } from 'react';

import { ReservationsContainer } from 'containers';

import { StyledCapsuleTabs } from './GuestCountSelection.styled';

const OPTIONS_LIMIT = 100;

type PropertiesType = {
  maxGuestCount: number;
};
export const GuestCountSelection = ({ maxGuestCount }: PropertiesType) => {
  const { selectedGuestCount, setSelectedGuestCount } =
    ReservationsContainer.useContainer();

  const optionsAmount = Math.min(maxGuestCount + 1, OPTIONS_LIMIT);
  const activeKey = useMemo(
    () =>
      (selectedGuestCount < optionsAmount
        ? selectedGuestCount
        : optionsAmount
      ).toString(),
    [optionsAmount, selectedGuestCount]
  );

  return (
    <StyledCapsuleTabs
      activeKey={activeKey}
      onChange={guestCount => setSelectedGuestCount(parseInt(guestCount, 10))}
    >
      {[...new Array(optionsAmount).keys()].slice(1).map(guestCount => (
        <StyledCapsuleTabs.Tab title={`${guestCount}`} key={`${guestCount}`} />
      ))}
      <StyledCapsuleTabs.Tab title="+" key={`${optionsAmount}`} />
    </StyledCapsuleTabs>
  );
};
