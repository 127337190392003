import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { environmentPathMap, PROD_BASE_PATH } from 'network';

const useHostUrlContainer = (initialState?: string) => {
  const [hostUrl] = useState(() => {
    if (
      typeof initialState === 'string' &&
      initialState in environmentPathMap
    ) {
      return environmentPathMap[initialState];
    }

    return PROD_BASE_PATH;
  });

  return { hostUrl };
};

export const HostUrlContainer = createContainer(useHostUrlContainer);
