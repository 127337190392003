import styled from 'styled-components';

export const TimePickerSelect = styled.select`
  width: 100%;
  min-width: 100%;
  min-width: -moz-available; /* WebKit-based browsers will ignore this. */
  min-width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  min-width: fill-available;
  height: 36px;

  color: #000000;
  text-align: center;
  text-align-last: center;
  font-size: 14px;

  appearance: none;
  border: 1px solid #7d96ff;
  border-radius: 6px;
  background-color: white;

  &:focus {
    outline: none;
  }
`;
