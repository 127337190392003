import { useIntl } from 'react-intl';
import { Popup } from 'antd-mobile';

import { BackButton } from 'components/general';

import {
  PopupContainer,
  StyledHighlight,
  StyledPrimaryButton,
  Title,
} from './ReservationsContactInfoPopup.styled';

type PropertiesType = {
  isVisible: boolean;
  locationName: string;
  publicPhoneNumber?: string | null;
  closePopup: () => void;
};
export const ReservationsContactInfoPopup = ({
  isVisible,
  locationName,
  publicPhoneNumber,
  closePopup,
}: PropertiesType) => {
  const intl = useIntl();

  return (
    <Popup
      visible={isVisible}
      getContainer={null}
      onClose={closePopup}
      onMaskClick={closePopup}
    >
      <PopupContainer>
        <BackButton
          clickHandler={closePopup}
          dataCy="reservation-contactInfo-back"
        />

        <Title data-cy="reservation-contactInfo-name">
          {intl.formatMessage(
            { id: 'reservations.reserveSlots.contactDirectly.title' },
            { locationName }
          )}
        </Title>

        <div>
          {intl.formatMessage(
            { id: 'reservations.reserveSlots.contactDirectly.message' },
            { locationName }
          )}
        </div>

        {publicPhoneNumber && (
          <>
            <StyledHighlight data-cy="reservation-contactInfo-phoneNumber">
              {intl.formatMessage({
                id: 'generic.contact.phone',
              })}
              : {publicPhoneNumber}
            </StyledHighlight>

            <a
              href={`tel:${publicPhoneNumber}`}
              data-cy="reservation-contactInfo-cta"
            >
              <StyledPrimaryButton block>
                {intl.formatMessage({
                  id: 'reservations.reserveSlots.contactDirectly.cta',
                })}
              </StyledPrimaryButton>
            </a>
          </>
        )}
      </PopupContainer>
    </Popup>
  );
};
