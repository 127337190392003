import {
  CreateReservationRequestType,
  CreateReservationResponseType,
  OpenReservationTimeSlotsRequestType,
} from 'types';

import { headers } from '../headers';
import { checkResponse } from '../utils';

export const getOpenTimeSlots = (
  hostUrl: string,
  disoverId: string,
  data: OpenReservationTimeSlotsRequestType
): Promise<{ openReservationTimestamps: number[] }> =>
  fetch(
    `${hostUrl}/api/v4/locationGroups/discover/${disoverId}/reservations/open`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    }
  )
    .then(checkResponse)
    .then(response => response.json());

export const createReservation = (
  hostUrl: string,
  disoverId: string,
  data: CreateReservationRequestType
): Promise<CreateReservationResponseType> =>
  fetch(
    `${hostUrl}/api/v4/locationGroups/discover/${disoverId}/reservations/reserve`,
    {
      method: 'POST',
      headers: {
        ...headers,
        ...(window.location.hash && {
          'X-Auth': window.location.hash?.slice(1),
        }),
      },
      body: JSON.stringify(data),
    }
  )
    .then(checkResponse)
    .then(response => response.json());
