import { RefObject, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Toast } from 'antd-mobile';
import moment from 'moment';
import { PhoneOutlined } from '@ant-design/icons';

import { toastMaskClassName } from 'constants/widgetIdentifiers';
import { AggregatedShiftsType } from 'types';
import { getDayNameOfDateEn } from 'utils';
import { RESERVATIONS_SEARCH_INTERVAL_IN_MINUTES } from 'constants/reservations';

export const generateSearchIntervalsInSeconds = () => {
  const items: number[] = [];
  const dayInSeconds = 24 * 60 * 60;
  const searchIntervalInSeconds = RESERVATIONS_SEARCH_INTERVAL_IN_MINUTES * 60;

  for (
    let searchInterval = 0;
    searchInterval <= dayInSeconds;
    searchInterval += searchIntervalInSeconds
  ) {
    items.push(searchInterval);
  }
  return items;
};

export const useAvailableTimeValues = (
  aggregatedShifts: AggregatedShiftsType,
  turnTime: number,
  selectDay: moment.Moment,
  earliestDateTime: moment.Moment,
  latestDateTime: moment.Moment
) =>
  useMemo(() => {
    let availableTimeOptions = generateSearchIntervalsInSeconds();
    const shiftsForDate = aggregatedShifts[getDayNameOfDateEn(selectDay)];

    // Filter shifts for first day additionally, considering minLeadTime
    if (earliestDateTime.isSame(selectDay, 'day')) {
      availableTimeOptions = availableTimeOptions.filter(
        interval =>
          earliestDateTime <=
          selectDay.clone().startOf('day').add(interval, 'seconds')
      );
    }

    // Filter shifts for last day additionally, considering maxLeadTime
    if (latestDateTime.isSame(selectDay, 'day')) {
      availableTimeOptions = availableTimeOptions.filter(
        interval =>
          latestDateTime >
          selectDay.clone().startOf('day').add(interval, 'seconds')
      );
    }

    availableTimeOptions = availableTimeOptions.filter(interval =>
      shiftsForDate.find(
        shift =>
          shift.startsAt <= interval && shift.endsAt - turnTime >= interval
      )
    );

    return availableTimeOptions.map(hourMinutesInSeconds => ({
      label: moment()
        .startOf('day')
        .add(hourMinutesInSeconds, 'seconds')
        .format('HH:mm'),
      value: hourMinutesInSeconds,
    }));
  }, [aggregatedShifts, selectDay, earliestDateTime, latestDateTime, turnTime]);

export const useGuestLimitExceeded = (
  maxGuestCount: number,
  selectedGuestCount: number,
  toastContainer: RefObject<HTMLElement>,
  publicPhone?: string | null
) => {
  const intl = useIntl();

  return useEffect(() => {
    if (selectedGuestCount > maxGuestCount) {
      // TODO: Add email
      Toast.show({
        duration: 0,
        getContainer: toastContainer.current,
        maskClassName: toastMaskClassName,
        icon: publicPhone ? <PhoneOutlined /> : undefined,
        content: (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div onClick={Toast.clear}>
            {intl.formatMessage(
              { id: 'reservations.maxGuestCount.exceeded' },
              { maxGuestCount }
            )}
            {publicPhone ? (
              <div>
                <a onClick={event => event.stopPropagation()} href="tel:012312">
                  {publicPhone}
                </a>
              </div>
            ) : null}
          </div>
        ),
      });
    } else {
      Toast.clear();
    }

    return () => Toast.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxGuestCount, publicPhone, selectedGuestCount]);
};
