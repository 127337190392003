import styled from 'styled-components';

import { PrimaryButton } from 'components/general';

export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;

  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const StyledInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledTerm = styled.span`
  margin-right: 12px;
`;

export const StyledValue = styled.span`
  font-weight: bold;
  word-break: break-word;
`;

export const StyledButton = styled(PrimaryButton)`
  margin-top: 40px;
  width: 100%;
`;
