import * as Yup from 'yup';
import validator from 'validator';

import { isValidCharacter, isValidPhoneNumber } from './validators';

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'phoneNumber',
  function handleError(errorMessage) {
    return this.test(
      'test-phone-number',
      errorMessage,
      value => !!value && isValidPhoneNumber(value)
    );
  }
);

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'safeString',
  function handleError(errorMessage) {
    return this.test('test-safe-string', errorMessage, value => {
      if (value === undefined) return true;
      return isValidCharacter(value);
    });
  }
);

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'zodAlignedEmail',
  function handleError(errorMessage) {
    return this.test(
      'test-zod-aligned-email-validaition',
      errorMessage,
      value => {
        if (value === undefined) {
          return true;
        }

        return !!validator.isEmail(value, {
          allow_display_name: false,
          require_display_name: false,
          allow_utf8_local_part: true,
          require_tld: true,
          allow_ip_domain: false,
          blacklisted_chars: "=',\\\\",
        });
      }
    );
  }
);

// declare module 'Yup' {
//   interface StringSchema<
//     TType extends Maybe<string> = string | undefined,
//     TContext extends AnyObject = AnyObject,
//     TOut extends TType = TType
//   > extends Yup.BaseSchema<TType, TContext, TOut> {
//     phoneNumber(phoneNumber: string): StringSchema<TType, TContext>;
//     safeString(string: string): StringSchema<TType, TContext>;
//   }
// }

const extendedYup: any = Yup;

export default extendedYup;
