import { useIntl } from 'react-intl';

import { StyledTextButton, StyledLeftOutlinedIcon } from './BackButton.styled';

type PropertiesType = {
  dataCy?: string;
  clickHandler: () => void;
};

export function BackButton({ dataCy, clickHandler }: PropertiesType) {
  const intl = useIntl();

  return (
    <StyledTextButton onClick={clickHandler} data-cy={dataCy}>
      <StyledLeftOutlinedIcon />
      {intl.formatMessage({ id: 'reservations.header.back' })}
    </StyledTextButton>
  );
}
