import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js/max';
import { yupResolver } from '@hookform/resolvers/yup';

import { PersonalReservationsInfoType } from 'types';

import { HostUrlContainer, ReservationsContainer } from 'containers';
import { PRICAVY_POLICY_LINK, TERMS_CONDITIONS_LINK } from 'constants/links';
import { createReservation } from 'network';
import { useToggle } from 'utils';

import { BackButton } from 'components/general';
import { FinishStep } from '../FinishStep';
import { useReserveSlotSchema } from '../reservations.schema';
import { ReserveSlotInfo } from '../ReserveSlotInfo';

import {
  TermsAndConditionsText,
  StyledInput,
  StyledErrorLabel,
  StyledBookButton,
} from './ReserveSlotForm.styled';

type PropsType = {
  discoverId: string;
  locationGroupName: string;
  reservationTimestamp: number;
  requireConfirmation: boolean;
};
export const ReserveSlotForm = ({
  discoverId,
  locationGroupName,
  reservationTimestamp,
  requireConfirmation,
}: PropsType) => {
  const intl = useIntl();
  const reserveSlotSchema = useReserveSlotSchema();

  const [isFormSubmitting, setIsFormSubmitting] = useToggle();

  const { hostUrl } = HostUrlContainer.useContainer();
  const { selectedGuestCount, resetReservation } =
    ReservationsContainer.useContainer();

  const [personalReservationsInfo, setPersonalReservationsInfo] =
    useState<PersonalReservationsInfoType>();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<PersonalReservationsInfoType>({
    resolver: yupResolver(reserveSlotSchema),
    mode: 'onTouched',
  });

  const onClickCreateReservation = (
    formValues: PersonalReservationsInfoType
  ) => {
    const { fullName, email, phone, message } = formValues;

    const formattedPhoneNumber = (
      parsePhoneNumber(phone, {
        defaultCountry: 'DE',
        extract: false,
      }) as PhoneNumber
    ).number;

    return createReservation(hostUrl, discoverId, {
      fullName,
      email,
      phone: formattedPhoneNumber as string,
      guestCount: selectedGuestCount,
      startsAt: reservationTimestamp,
      source: 'WIDGET',
      ...(message && { customerMessage: message }),
    });
  };

  const onSubmit = (formValues: PersonalReservationsInfoType) => {
    setIsFormSubmitting();

    onClickCreateReservation(formValues)
      .then(({ state }) => {
        if (state === 'PAYMENT_REQUIRED') {
          // TODO: state = PAYMENT_REQUIRED should not occur, as we redirect to discovery service in previous step,
          // if reservation no-show fee is configured
          throw new Error(
            'Invalid reservation state PAYMENT_REQUIRED, redirect should occur in previous step'
          );
        }
        setPersonalReservationsInfo(formValues);
      })
      .catch(error_ => {
        console.error(error_);
      });

    setIsFormSubmitting();
  };

  if (personalReservationsInfo) {
    return (
      <FinishStep
        guestCount={selectedGuestCount}
        locationGroupName={locationGroupName}
        personalReservationsInfo={personalReservationsInfo}
        reservationTimestamp={reservationTimestamp}
        requireConfirmation={requireConfirmation}
      />
    );
  }

  return (
    <>
      <BackButton clickHandler={resetReservation} />

      <ReserveSlotInfo
        guestCount={selectedGuestCount}
        locationGroupName={locationGroupName}
        reservationTimestamp={reservationTimestamp}
      />

      <form onSubmit={handleSubmit(onSubmit, console.error)} autoComplete="on">
        <StyledInput
          {...register('fullName')}
          placeholder={`*${intl.formatMessage({
            id: 'reservations.reserveSlots.form.name',
          })}`}
          $isInvalid={!!errors.fullName}
        />
        <StyledErrorLabel>{errors.fullName?.message}</StyledErrorLabel>

        <StyledInput
          {...register('phone')}
          type="tel"
          autoComplete="tel"
          placeholder={`*${intl.formatMessage({
            id: 'reservations.reserveSlots.form.phone',
          })}`}
          $isInvalid={!!errors.phone}
        />
        <StyledErrorLabel>{errors.phone?.message}</StyledErrorLabel>
        <StyledInput
          {...register('email')}
          type="email"
          autoComplete="email"
          placeholder={`*${intl.formatMessage({
            id: 'reservations.reserveSlots.form.email',
          })}`}
          $isInvalid={!!errors.email}
        />
        <StyledErrorLabel>{errors.email?.message}</StyledErrorLabel>
        <StyledInput
          {...register('message')}
          placeholder={intl.formatMessage({
            id: 'reservations.reserveSlots.form.message',
          })}
          $isInvalid={!!errors.message}
        />
        <StyledErrorLabel>{errors.message?.message}</StyledErrorLabel>

        <StyledBookButton
          loading={isFormSubmitting}
          disabled={isFormSubmitting}
          type="submit"
        >
          {intl.formatMessage({
            id: requireConfirmation
              ? 'reservations.reserveSlots.submit.request'
              : 'reservations.reserveSlots.submit',
          })}
        </StyledBookButton>

        <TermsAndConditionsText>
          {intl.formatMessage(
            {
              id: requireConfirmation
                ? 'reservations.agreements.request'
                : 'reservations.agreements',
            },
            {
              termsAndConditionsLink: (
                <a
                  href={TERMS_CONDITIONS_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({
                    id: 'reservations.termsAndConditions',
                  })}
                </a>
              ),
              privacyPolicyLink: (
                <a
                  href={PRICAVY_POLICY_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'reservations.privacyPolicy' })}
                </a>
              ),
            }
          )}
        </TermsAndConditionsText>
      </form>
    </>
  );
};
