export const colors = {
  white: '#ffffff',
  light_black: 'rgba(0, 0, 0, 0.15)',
  dark_black: 'rgba(0, 0, 0, 0.87)',
  blue: '#7d96ff',
  light_blue_1: '#afbeff',
  light_blue_2: 'rgb(155, 173, 191)',
  light_blue_3: 'rgb(218, 224, 231)',
  grey_1: '#f5f5f5',
};

// TODO: Adapt to widget color scheme
// Nr. 1 - Very light blue: #f7f8ff
// Nr. 3 - Highlight blue A: (primary button): #afbeff
// Nr. 4 - Highlight blue B: #7d96ff
// Nr. 5 - Dark blue (secondary button) : #5768b2
