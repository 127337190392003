import { useIntl } from 'react-intl';
import { CalendarOutlined } from '@ant-design/icons';

import { ReservationsContainer } from 'containers';
import { PersonalReservationsInfoType } from 'types';

import { ReserveSlotInfo } from '../ReserveSlotInfo';
import {
  Description,
  StyledButton,
  StyledInfoItem,
  StyledTitleWrapper,
  StyledTerm,
  StyledValue,
  StyledWrapper,
} from './FinishStep.styled';

type PropertiesType = {
  guestCount: number;
  locationGroupName: string;
  personalReservationsInfo: PersonalReservationsInfoType;
  reservationTimestamp: number;
  requireConfirmation: boolean;
};
export const FinishStep = ({
  guestCount,
  locationGroupName,
  personalReservationsInfo: { fullName, email, phone, message },
  reservationTimestamp,
  requireConfirmation,
}: PropertiesType) => {
  const intl = useIntl();
  const { resetReservation } = ReservationsContainer.useContainer();

  return (
    <>
      <StyledTitleWrapper>
        <CalendarOutlined />
        {intl.formatMessage({
          id: requireConfirmation
            ? 'reservations.reserveSuccess.title.request'
            : 'reservations.reserveSuccess.title',
        })}
        <Description>
          {intl.formatMessage({
            id: requireConfirmation
              ? 'reservations.reserveSuccess.description.request'
              : 'reservations.reserveSuccess.description',
          })}
        </Description>
      </StyledTitleWrapper>
      <ReserveSlotInfo
        guestCount={guestCount}
        locationGroupName={locationGroupName}
        reservationTimestamp={reservationTimestamp}
      />

      <StyledWrapper>
        <StyledInfoItem>
          <StyledTerm>
            {intl.formatMessage({
              id: 'reservations.reserveSlots.form.name',
            })}
            :
          </StyledTerm>
          <StyledValue>{fullName}</StyledValue>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledTerm>
            {intl.formatMessage({
              id: 'reservations.reserveSlots.form.phone',
            })}
            :
          </StyledTerm>
          <StyledValue>{phone}</StyledValue>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledTerm>
            {intl.formatMessage({
              id: 'reservations.reserveSlots.form.email',
            })}
            :
          </StyledTerm>
          <StyledValue>{email}</StyledValue>
        </StyledInfoItem>
        {message && (
          <StyledInfoItem>
            <StyledTerm>
              {intl.formatMessage({
                id: 'reservations.reserveSlots.form.message',
              })}
              :
            </StyledTerm>
            <StyledValue>{message}</StyledValue>
          </StyledInfoItem>
        )}
      </StyledWrapper>

      <StyledButton onClick={resetReservation}>
        {intl.formatMessage({ id: 'reservations.reserveSuccess.okay' })}
      </StyledButton>
    </>
  );
};
