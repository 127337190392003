export type SupportedLanguageType = 'de' | 'en';

export const defaultLanguage: SupportedLanguageType = 'en';

const isSupportedLanguage = (lang: string): lang is SupportedLanguageType =>
  lang === 'de' || lang === 'en';

export const getLanguage = (): SupportedLanguageType => {
  const queryParameters = new URLSearchParams(window.location.search);
  const overwriteLanguage = queryParameters.get('locale');

  if (overwriteLanguage && isSupportedLanguage(overwriteLanguage)) {
    return overwriteLanguage;
  }
  const browserLanguage = navigator.language.split(/[_-]/)[0];
  if (isSupportedLanguage(browserLanguage)) {
    return browserLanguage;
  }

  return defaultLanguage;
};
