export class ApiError extends Error {
  response: Response;

  status: number;

  constructor(response: Response) {
    super();
    this.response = response;
    this.status = response.status;
    this.message = `Request to ${response.url} failed with status ${response.status}`;
  }
}

export const getRequest = (path: string, headers: Record<string, string>) => {
  return fetch(path, {
    method: 'GET',
    headers,
  })
    .then(response => {
      if (response.ok) {
        return response.text();
      }

      throw new ApiError(response);
    })
    .then(payload => {
      try {
        return JSON.parse(payload);
      } catch {
        // This is fine:
        // Payload is just text like "OK"
        return payload;
      }
    });
};

export const checkResponse = (response: Response) => {
  if (!response.ok) {
    throw new ApiError(response);
  }

  return response;
};

/**
   Performs a fetch and returns the json parsed response on status OK. Allows
   to return specific responses for given status codes or by generic return on
   errors.
   * @param {string} method - HTTP request method ('HEAD' | 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE')
   * @param {string} path - URI
   * @param {object} headers - Request headers object
   * @param {object} responseHandlersByStatus - object with status codes as key and return callback as value
   * @param {function} errorResponseHandler - callback that returns fallback response on status code >= 400
   * @returns Promise of json parsed fetch response
   */
export const requestWithStatusHandlers = (
  method: 'HEAD' | 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  path: string,
  headers: Record<string, string>,
  responseHandlersByStatus?: {
    [status: number]: (response: Response) => unknown;
  },
  errorResponseHandler?: (response: Response) => unknown
) => {
  return fetch(path, {
    method,
    headers,
  })
    .then(response => {
      if (
        responseHandlersByStatus &&
        responseHandlersByStatus[response.status] &&
        typeof responseHandlersByStatus[response.status] === 'function'
      ) {
        return responseHandlersByStatus[response.status](response);
      }
      if (
        errorResponseHandler &&
        typeof errorResponseHandler === 'function' &&
        response.status &&
        response.status >= 400
      ) {
        return errorResponseHandler(response);
      }
      if (response.ok) {
        return response.json();
      }

      throw new ApiError(response);
    })
    .catch(error => {
      if (error instanceof ApiError) {
        console.error(`Error requesting ${method} ${path}`, error);
      }

      throw error;
    });
};
