import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { validate } from 'uuid';

import { ReservationsModal } from 'components/ReservationsModal';
import { messages } from 'messages';
import { getLanguage } from 'utils';

const queryClient = new QueryClient();

type PropsType = {
  discoverId?: string;
  hideDefaultButton: boolean;
};
export const App = ({ discoverId, hideDefaultButton }: PropsType) => {
  // const trackerInstance = useDeepCompareMemo(
  //   () =>
  //     createInstance({
  //       urlBase: MATOMO_BASE_URL,
  //       siteId: MATOMO_SITE_ID,
  //       userId: discoverId,
  //       disabled: hostUrl !== PROD_BASE_PATH || !discoverId,
  //       heartBeat: {
  //         active: true,
  //         seconds: 10,
  //       },
  //       linkTracking: true,
  //       configurations: {
  //         disableCookies: true,
  //         setSecureCookie: true,
  //         setRequestMethod: 'POST',
  //       },
  //     }),
  //   [discoverId, hostUrl, MATOMO_BASE_URL, MATOMO_SITE_ID]
  // );

  if (!discoverId || !validate(discoverId)) {
    console.error('Invalid discoverId provided:', discoverId);
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      {/* <MatomoProvider value={trackerInstance}> */}
      <IntlProvider
        locale={getLanguage()}
        messages={messages[getLanguage()]}
        wrapRichTextChunksInFragment
      >
        <>
          {/* TODO: Wrap with react-error-boundary */}
          <ReservationsModal
            discoverId={discoverId}
            hideDefaultButton={hideDefaultButton}
          />
        </>
      </IntlProvider>
      {/* </MatomoProvider> */}
    </QueryClientProvider>
  );
};
