{
    "reservations.site.title": "luca Reservations",
    "reservations.imprint": "Imprint",
    "reservations.termsOfUse": "Terms of use",
    "header.title": "Reservations",
    "error.headline": "This didn't work!",
    "error.description": "Something went wrong. Please try reloading the page or come back later. If the error still occurs, please get in touch with locations@luca-app.de.",
    "search": "Search",
    "search.info": "Please provide a time range for your reservation.",
    "search.header": "Reservations",
    "search.guests": "Guest count",
    "reservations.guestCount.people": "{count} {count, plural, one {Person} other {People}}",
    "reservations.errorNotification.noSlotsAvailable": "Unfortunately, there are no available time slots between {earliestStartingTime} and {latestStartingTime}. Please choose a different time and search again.",
    "reservations.agreements": "By submitting your reservation you agree to the {termsAndConditionsLink}. Information about the use of your data can be found in our {privacyPolicyLink}.",
    "reservations.reserveSlots.submit": "Book table",
    "reservations.reserveSlots.form.firstName": "First name",
    "reservations.reserveSlots.form.lastName": "Last name",
    "reservations.reserveSlots.form.phone": "Phone number",
    "reservations.reserveSlots.form.email": "Email",
    "reservations.reserveSlots.form.state": "State",
    "reservations.reserveSlots.form.note": "Note",
    "reservations.reserveSlots.form.phone.required": "Phone number is required",
    "reservations.reserveSlots.form.firstNameError": "First name is required",
    "reservations.reserveSlots.form.lastNameError": "Last name is required",
    "reservations.reserveSlots.form.invalidEmailError": "Please enter a valid email address",
    "reservations.reserveSlots.form.stateError": "State is required",
    "reservations.reserveSlots.form.message": "More information",
    "reservations.reserveSlot.reservationError": "An unexpected error occurred. Please try again.",
    "reservations.reserveSlot.reservationError.description": "Something went wrong :/",
    "error.place.notFound.headline": "Not found",
    "error.place.notFound.description": "The restaurant you are looking for seems not to be available anymore.",
    "error.place.notFound.goBackButton": "Go back to Discover",
    "reservations.reserveSlot.overview.date": "Date",
    "reservations.reserveSlot.overview.time": "Time",
    "reservations.reserveSlot.overview.guestCount": "Number of guests",
    "reservations.header.back": "Back",
    "reservations.reserveSuccess.title": "Your reservation was successful",
    "reservations.reserveSuccess.description": "We are looking forward to your visit!",
    "reservations.reserveSuccess.okay": "Okay",
    "reservations.reserveSuccess.locationName": "Location",
    "reservations.reserveSlots.form.emailError": "Email is required",
    "reservations.chooseTime": "Select time",
    "reservations.reserveSlots.form.name": "Name",
    "reservations.privacyPolicy": "privacy policy",
    "reservations.reserveSlots.form.phone.format": "Invalid phone number",
    "reservations.reserveSlots.form.invalidCharactersError": "The entered message contains invalid characters.",
    "reservations.reserveSlots.form.lengthError": "The entered message is too long (maximum 255 characters).",
    "reservations.shifts.unavailable": "No free slots",
    "reservations.termsAndConditions": "Terms and conditions",
    "reservations.reserveSlots.form.fullNameError": "Name is required",
    "generic.cancel": "Cancel",
    "generic.confirm": "Confirm",
    "discovery.site.title": "luca Discover",
    "search.name": "Search location name ...",
    "geoLocation.germanyWide": "Search in Germany",
    "geoLocationDrawer.title": "Where do you want to search?",
    "search.geoLocation": "Search for location, street, etc.",
    "filter.all": "All",
    "filter.restaurant": "Restaurant",
    "filter.hotel": "Hotel",
    "filter.store": "Store",
    "filter.other": "Other",
    "filter.base": "Other",
    "discovery.imprint": "Imprint",
    "discovery.termsOfUse": "Terms of use",
    "search.loading": "Loading ...",
    "search.geoLocationSearching": "Retrieving location ...",
    "search.useMyLocation": "Use my location",
    "online.headline": "Internet connection restored",
    "online.details": "You can now use the app as usual",
    "offline.headline": "No internet connection",
    "offline.details": "There seems to be no internet connection at the moment. We will inform you as soon as the connection is restored.",
    "general.notification.successTitle": "That worked!",
    "general.notification.errorTitle": "Oops, something went wrong!",
    "general.notification.infoTitle": "Information!",
    "discovery.noLocation": "Unfortunately, we could not find a location with this name.",
    "search.end": "End of list",
    "discovery.locationPermission": "Please give permission to access your location.",
    "geolocation.retrieved.success": "Your current location is enabled.",
    "geolocation.system.services.disabled": "Your current location is disabled in your system or browser.",
    "geolocation.not.supported.byBrowser": "Your current location is not supported by your browser.",
    "geolocation.permission.denied": "Access to your current location has been denied. Please check your settings.",
    "geolocation.permission.unavailable": "Access to your current location is not available in your browser.",
    "search.searchBy": "Search by",
    "search.city": "City",
    "search.address": "Address",
    "guide.reportAbuse": "Report a problem",
    "guide.imprint": "Imprint",
    "guide.termsOfUse": "Terms of use",
    "guide.openingHours.headline": "Opening hours",
    "guide.plan.headline": "Plan",
    "guide.menu.headline": "Menu",
    "guide.timetable.headline": "Timetable",
    "guide.information.headline": "Infos",
    "guide.reservation.headline": "Reserve",
    "guide.weekday.mon": "Monday",
    "guide.weekday.tue": "Tuesday",
    "guide.weekday.wed": "Wednesday",
    "guide.weekday.thu": "Thursday",
    "guide.weekday.fri": "Friday",
    "guide.weekday.sat": "Saturday",
    "guide.weekday.sun": "Sunday",
    "guide.openingHours.closed": "Closed",
    "guide.website.headline": "Website",
    "guide.navigate.headline": "Navigate",
    "guide.welcomeTitle": "Welcome to {name}",
    "guide.closedToday": "We are closed today",
    "guide.closingAt": "We close today at {time}",
    "guide.start.title": "Welcome to luca",
    "guide.start.cta": "Find location",
    "guide.start.description": "If you want to find special locations in your area, you've come to the right place: Click on \"Find location\" and re-discover your neighbourhood!",
    "guide.contactSection.title": "Contact us",
    "hashtags.SPANISH": "Spanish",
    "hashtags.GERMAN": "German",
    "hashtags.KOREAN": "Korean",
    "hashtags.AUSTRALIAN": "Australian",
    "hashtags.MEXICAN": "Mexican",
    "hashtags.GREEK": "Greek",
    "hashtags.FILIPINO": "Filipino",
    "hashtags.INDIAN": "Indian",
    "hashtags.ITALIAN": "Italian",
    "hashtags.CHINESE": "Chinese",
    "hashtags.AMERICAN": "American",
    "hashtags.ASIAN": "Asian",
    "hashtags.EUROPEAN": "European",
    "hashtags.ETHIOPIAN": "Ethiopian",
    "hashtags.COLOMBIAN": "Colombian",
    "hashtags.BRAZILIAN": "Brazilian",
    "hashtags.PORTUGESE": "Portugese",
    "hashtags.MEDITERRANEAN": "Mediterranean",
    "hashtags.THAI": "Thai",
    "hashtags.FRENCH": "French",
    "hashtags.JAPANESE": "Japanese",
    "hashtags.LEBANESE": "Lebanese",
    "hashtags.SOUTH_AFRICAN": "South African",
    "hashtags.HALAL": "Halal",
    "hashtags.VEGETARIAN_FRIENDLY": "Vegetarian",
    "hashtags.VEGAN_OPTIONS": "Vegan",
    "hashtags.COFFEE": "Coffee",
    "hashtags.DESSERT": "Dessert",
    "guide.open": "Open",
    "guide.call": "Call",
    "guide.follow": "Follow us",
    "guide.locationPermission": "Please give permission to access your location.",
    "guide.more": "More",
    "menu.title": "Menu",
    "menu.productInfo": "Product information",
    "menu. allergens": "Allergens",
    "openingHours.weekday.openAt": "We open at {time}.",
    "openingHours.weekday.closedAt": "We close at {time}.",
    "openingHours.weekday.closedToday": "We are closed today, but open again on {weekday} at {time}.",
    "guide.bookTable": "Book a table",
    "reservations.reserveSlot.overview.dateTime": "Date and time",
    "reservations.maxGuestCount.exceeded": "This location currently only supports online reservations for up to {maxGuestCount} guests. Please contact the location directly for larger groups.",
    "reservations.noShiftsAvailable": "No reservation times found.{br}Please select a different day.",
    "discovery.loadMore": "Load more",
    "hashtags.ISRAELI": "Israeli",
    "hashtags.INTERNATIONAL": "International",
    "hashtags.PERUVIAN": "Peruvian",
    "hashtags.VIETNAMESE": "Vietnamese",
    "hashtags.STEAK": "Steak",
    "hashtags.BURGER": "Burger",
    "hashtags.BAR": "Bar",
    "hashtags.KOSHER": "Kosher",
    "hashtags.GLUTEN_FREE": "Glutenfree",
    "reservations.reserveSlots.submit.request": "Request table",
    "reservations.agreements.request": "By submitting your reservation request you agree to the {termsAndConditionsLink}. Information about the use of your data can be found in our {privacyPolicyLink}.",
    "reservations.reserveSuccess.description.request": "As soon as your reservation request is processed, you will receive a message.",
    "reservations.reserveSuccess.title.request": "Your reservation request has been sent",
    "reservations.errorNotification.noSlotsAvailable.generic": "Unfortunately, there are no free time slots available for your selected time. Please select another time and search again.",
    "widget.button.title": "Book a table",
    "discovery.discoveryView.title": "Discover",
    "reservations.fee.pending": "Make the payment to complete your reservation.",
    "reservations.fee.description": "Your payment is still in process.",
    "reservations.fee.button": "Start payment",
    "generic.continue": "Continue",
    "reservations.fee.notification": "This restaurant requires a no-show fee ({cancellationFeePerPerson} per guest). The fee will be automatically refunded once your reservation is completed or if cancelled 24 hours before.",
    "generic.loading": "Loading ...",
    "generic.schema.invalidCharacters": "Your entered value contains invalid characters.",
    "generic.schema.lengthError": "The entered value is too long (maximum {max} characters).",
    "generic.contact.phone": "Phone number",
    "reservations.reserveSlots.contactDirectly.title": "Contact {locationName}",
    "reservations.reserveSlots.contactDirectly.message": "{locationName} needs more lead time to accept online reservations. Feel free to call {locationName} to see if a table is still available!",
    "reservations.reserveSlots.contactDirectly.cta": "Call location",
    "reservations.reserveSlots.availableTimeSlots.earlier": "Earlier",
    "reservations.noncapture.fee.notification": "This restaurant charges a no-show fee of {cancellationFeePerPerson} per guest if you do not show up or cancel on short notice. The fee will not be charged if you honor your reservation or cancel no later than [cancellationDateTime]."
}