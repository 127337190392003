import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useMountEffect, useToggle } from '@react-hookz/web';

import { widgetTriggerIdentifier } from 'constants/widgetIdentifiers';
import { ReservationsContainer } from 'containers';

import { ReservationsModalContent } from './ReservationsModalContent.react';
import {
  StyledButton,
  StyledWidgetContainer,
} from './ReservationsModal.styled';
import { StyledFrame } from './StyledComponentsFrame.react';

type PropsType = {
  discoverId: string;
  hideDefaultButton: boolean;
};
export const ReservationsModal = ({
  discoverId,
  hideDefaultButton,
}: PropsType) => {
  const intl = useIntl();
  const iframeRef = useRef(null);
  const [isWidgetOpen, toggleIsWidgetOpen] = useToggle();

  const handleWidgetTriggerClick = () => {
    toggleIsWidgetOpen();
  };

  useMountEffect(() => {
    const allWidgetHandlers = document.querySelectorAll(
      widgetTriggerIdentifier
    );

    allWidgetHandlers.forEach(element => {
      element.addEventListener('click', handleWidgetTriggerClick);
    });

    return () => {
      allWidgetHandlers.forEach(element => {
        element.removeEventListener('click', handleWidgetTriggerClick);
      });
    };
  });

  return (
    <>
      {!hideDefaultButton && (
        <StyledButton
          onClick={toggleIsWidgetOpen}
          data-cy="reservation-open-widget"
        >
          {intl.formatMessage({ id: 'widget.button.title' })}
        </StyledButton>
      )}
      <StyledWidgetContainer $isVisible={isWidgetOpen}>
        <ReservationsContainer.Provider>
          <StyledFrame innerRef={iframeRef}>
            <ReservationsModalContent
              discoverId={discoverId}
              closeWidget={toggleIsWidgetOpen}
            />
          </StyledFrame>
        </ReservationsContainer.Provider>
      </StyledWidgetContainer>
    </>
  );
};
