import styled from 'styled-components';

import { PrimaryButton } from 'components/general';

export const StyledInput = styled.input<{ $isInvalid: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius};
  height: 56px;
  padding: 0 16px;
  width: calc(100% - 32px);
  border: 1px solid
    ${({ $isInvalid, theme }) =>
      $isInvalid ? theme.colors.error : 'rgb(116, 116, 128)'};
`;

export const TermsAndConditionsText = styled.div`
  font-size: 12px;
`;

export const StyledErrorLabel = styled.div`
  color: ${({ theme }) => theme.colors.error};
  padding-top: 4px;
  padding-left: 12px;
  margin-bottom: 12px;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledBookButton = styled(PrimaryButton)`
  width: 100%;
  margin: 24px 0;
`;
