import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd-mobile';

const Z_INDEX_VALUE = 999_999;

export const StyledCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  font-size: 16px;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

// The button is not within our iFrame and therefore prone to be affected by generic styles
// Therefore, these styles are set as !important
export const StyledButton = styled(Button)`
  z-index: ${Z_INDEX_VALUE};
  position: fixed !important;
  bottom: 24px !important;
  right: 24px !important;
  padding: 16px 24px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4) !important;

  font-size: 14px !important;
  font-weight: bold !important;
  color: #5768b2 !important;
  text-transform: uppercase !important;
`;

export const StyledWidgetContainer = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  bottom: 24px;
  right: 24px;
  background-color: white;
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};

  width: 360px;
  height: 800px;
  max-height: calc(100vh - 48px);
  z-index: ${Z_INDEX_VALUE};
`;

export const ModalContentWrapper = styled.div`
  padding: 24px 16px;
`;

export const StyledPoweredByLucaLogo = styled.img`
  display: block;
  width: 118px;
  margin-top: auto;
  margin-left: auto;
  padding-top: 24px;
`;
