import moment from 'moment';

import { DaysKeysType } from 'types';

export const getDayNameOfDateEn = (date: moment.Moment) =>
  date.locale('en').format('dddd').toLowerCase() as DaysKeysType;

export const getFormattedDate = (unixTimestamp: number) =>
  moment.unix(unixTimestamp).format('L');

export const getFormattedTime = (unixTimestamp: number): string =>
  moment.unix(unixTimestamp).format('HH:mm');

export const getNearestFutureMinutes = (
  momentTimestamp: moment.Moment,
  intervalInMinutes = 15
) => {
  const roundedMinutes =
    Math.ceil(momentTimestamp.minute() / intervalInMinutes) * intervalInMinutes;

  return momentTimestamp.clone().minute(roundedMinutes).second(0).unix();
};
