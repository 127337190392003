import React from 'react';

import { NotFound } from 'components/NotFound';
import { baseTheme } from 'constants/';
import { HostUrlContainer } from 'containers';
import { useGetLocationGroup, useGetThemeByDiscoverId } from 'network';
import {
  CustomThemeType,
  LocationGroupReservationsEnabledAndConfiguredType,
} from 'types';
import { DefaultTheme } from 'styled-components';

type WithLocationGroupPropertiesType = {
  locationGroup: LocationGroupReservationsEnabledAndConfiguredType;
  theme: CustomThemeType;
};

type PropertiesType = {
  discoverId: string;
};
export const withLocationGroup =
  <P extends object>(
    WrappedComponent: React.ComponentType<P & WithLocationGroupPropertiesType>
  ): React.FC<P & PropertiesType> =>
  properties => {
    const { discoverId } = properties;
    const { hostUrl } = HostUrlContainer.useContainer();

    const {
      error: locationGroupError,
      isLoading: isLocationGroupLoading,
      data: locationGroup,
    } = useGetLocationGroup(hostUrl, discoverId);

    const {
      error: themeAndSettingsError,
      isLoading: isThemeAndSettingsLoading,
      data: themeAndSettings,
    } = useGetThemeByDiscoverId(hostUrl, discoverId);

    // TODO: Implement LoadingSpinner
    if (isLocationGroupLoading || isThemeAndSettingsLoading) {
      return null;
    }

    const mapCustomToBaseTheme = (
      customTheme: CustomThemeType
    ): DefaultTheme => ({
      borderRadius: '6px',
      colors: {
        primaryColor: customTheme.primaryColor || '#262626',
        secondaryColor: customTheme.buttonTextColor || '#ffffff99',
        backgroundColor: customTheme.pageBackgroundColor || '#000',
        secondaryBackgroundColor: customTheme.tileBackgroundColor || '#8bc0ea',
        primaryTextColor: customTheme.textColor || '#fff',
        secondaryTextColor: customTheme.textColor || '#000',
        trivialTextColor: '#a0c8ff',
        notificationTextColor: '#c078ea',
        error: '#f16704',
      },
      buttons: {
        main: customTheme.buttonTextColor || 'rgb(195, 206, 217)',
        focus: customTheme.buttonTextColor || 'rgb(155, 173, 191)',
        hover: customTheme.buttonTextColor || 'rgb(155, 173, 191)',
        disabled: customTheme.buttonTextColor || 'rgb(155, 173, 191)',
      },
    });

    const customTheme = themeAndSettings?.theme
      ? mapCustomToBaseTheme(themeAndSettings.theme)
      : baseTheme;

    if (
      themeAndSettingsError ||
      locationGroupError ||
      !locationGroup?.reservationsFullyConfigured
    ) {
      return <NotFound />;
    }

    return (
      <WrappedComponent
        locationGroup={locationGroup}
        theme={customTheme}
        {...properties}
      />
    );
  };
