{
    "reservations.site.title": "luca Reservierungen",
    "reservations.imprint": "Impressum",
    "reservations.termsOfUse": "Nutzungsbedingungen",
    "header.title": "Reservierungen",
    "error.headline": "Das hat nicht geklappt!",
    "error.description": "Da ist leider etwas schiefgelaufen. Bitte versuche, die Seite neu zu laden oder probiere es später noch einmal. Wenn es weiterhin nicht klappt, wende dich bitte an locations@luca-app.de.",
    "search": "Suchen",
    "search.info": "Bitte gib einen Zeitraum an, in dem du reservieren willst.",
    "search.header": "Reservierung",
    "search.guests": "Gästeanzahl",
    "reservations.guestCount.people": "{count} {count, plural, one {Person} other {People}}",
    "reservations.errorNotification.noSlotsAvailable": "Leider sind keine freien Tische zwischen {earliestStartingTime} und {latestStartingTime} verfügbar. Bitte wähle eine andere Uhrzeit aus und suche nochmal.",
    "reservations.agreements": "Durch das Absenden deiner Reservierung stimmst du den {termsAndConditionsLink} zu. Informationen zur Verwendung deiner Daten findest du in unseren {privacyPolicyLink}.",
    "reservations.reserveSlots.submit": "Tisch reservieren",
    "reservations.reserveSlots.form.firstName": "Vorname",
    "reservations.reserveSlots.form.lastName": "Nachname",
    "reservations.reserveSlots.form.phone": "Telefonnummer",
    "reservations.reserveSlots.form.email": "E-Mail",
    "reservations.reserveSlots.form.state": "Land",
    "reservations.reserveSlots.form.note": "Anmerkung",
    "reservations.reserveSlots.form.phone.required": "Telefonnummer ist erforderlich",
    "reservations.reserveSlots.form.firstNameError": "Vorname ist erforderlich",
    "reservations.reserveSlots.form.lastNameError": "Nachname ist erforderlich",
    "reservations.reserveSlots.form.invalidEmailError": "Bitte gib eine gültige E-Mail-Adresse an",
    "reservations.reserveSlots.form.stateError": "Land ist erforderlich",
    "reservations.reserveSlots.form.message": "Weitere Informationen",
    "reservations.reserveSlot.reservationError": "Die Reservierung konnte nicht abgeschlossen werden. Bitte versuche es erneut.",
    "reservations.reserveSlot.reservationError.description": "Etwas ist schiefgelaufen :/",
    "error.place.notFound.headline": "Nicht gefunden",
    "error.place.notFound.description": "Das gewünschte Restaurant scheint nicht mehr verfügbar zu sein.",
    "error.place.notFound.goBackButton": "Zurück zu Entdecken",
    "reservations.reserveSlot.overview.date": "Datum",
    "reservations.reserveSlot.overview.time": "Uhrzeit",
    "reservations.reserveSlot.overview.guestCount": "Personenzahl",
    "reservations.header.back": "Zurück",
    "reservations.reserveSuccess.title": "Deine Reservierung wurde bestätigt",
    "reservations.reserveSuccess.description": "Wir freuen uns auf deinen Besuch!",
    "reservations.reserveSuccess.okay": "Okay",
    "reservations.reserveSuccess.locationName": "Standort",
    "reservations.reserveSlots.form.emailError": "E-Mail ist erforderlich",
    "reservations.chooseTime": "Zeit auswählen",
    "reservations.reserveSlots.form.name": "Name",
    "reservations.privacyPolicy": "Datenschutzbestimmungen",
    "reservations.reserveSlots.form.phone.format": "Ungültige Telefonnummer",
    "reservations.reserveSlots.form.invalidCharactersError": "Die eingegebene Nachricht enthält ungültige Zeichen.",
    "reservations.reserveSlots.form.lengthError": "Die eingegebene Nachricht ist zu lang (maximal 255 Zeichen).",
    "reservations.shifts.unavailable": "Keine freien Optionen",
    "reservations.termsAndConditions": "Nutzungsbedingungen",
    "reservations.reserveSlots.form.fullNameError": "Name ist erforderlich",
    "generic.cancel": "Abbrechen",
    "generic.confirm": "Bestätigen",
    "discovery.site.title": "luca Entdecken",
    "search.name": "Restaurantnamen suchen ...",
    "geoLocation.germanyWide": "Deutschlandweit suchen",
    "geoLocationDrawer.title": "Wo möchtest du suchen?",
    "search.geoLocation": "Ort, Straße, etc. suchen ...",
    "filter.all": "Alle",
    "filter.restaurant": "Restaurant",
    "filter.hotel": "Hotel",
    "filter.store": "Geschäft",
    "filter.other": "Andere",
    "filter.base": "Andere",
    "discovery.imprint": "Impressum",
    "discovery.termsOfUse": "Nutzungsbedingungen",
    "search.loading": "Lädt ...",
    "search.geoLocationSearching": "Standort wird ermittelt ...",
    "search.useMyLocation": "Meinen Standort nutzen",
    "online.headline": "Internetverbindung wiederhergestellt",
    "online.details": "Du kannst die App nun wie gewohnt nutzen.",
    "offline.headline": "Keine Internetverbindung",
    "offline.details": "Es scheint derzeit keine Internetverbindung zu bestehen. Wir informieren dich, sobald die Verbindung wiederhergestellt wurde.",
    "general.notification.successTitle": "Das hat geklappt!",
    "general.notification.errorTitle": "Ups, etwas ist schiefgelaufen!",
    "general.notification.infoTitle": "Information!",
    "discovery.noLocation": "Wir konnten leider keine Location mit diesem Namen finden.",
    "search.end": "Ende der Liste",
    "discovery.locationPermission": "Bitte erlaube uns, auf deinen Standort zuzugreifen.",
    "geolocation.retrieved.success": "Dein aktueller Standort ist aktiviert.",
    "geolocation.system.services.disabled": "Dein aktueller Standort ist in deinem System oder Browser deaktiviert.",
    "geolocation.not.supported.byBrowser": "Dein aktueller Standort wird von deinem Browser nicht unterstützt.",
    "geolocation.permission.denied": "Der Zugriff auf deinen aktuellen Standort wurde verweigert. Bitte überprüfe deine Einstellungen.",
    "geolocation.permission.unavailable": "Der Zugriff auf deinen aktuellen Standort ist in deinem Browser nicht verfügbar.",
    "search.searchBy": "Suche nach",
    "search.city": "Stadt",
    "search.address": "Adresse",
    "guide.reportAbuse": "Problem melden",
    "guide.imprint": "Impressum",
    "guide.termsOfUse": "Nutzungsbedingungen",
    "guide.openingHours.headline": "Öffnungszeiten",
    "guide.plan.headline": "Lageplan",
    "guide.menu.headline": "Menü",
    "guide.timetable.headline": "Programm",
    "guide.information.headline": "Infos",
    "guide.reservation.headline": "Reservieren",
    "guide.weekday.mon": "Montag",
    "guide.weekday.tue": "Dienstag",
    "guide.weekday.wed": "Mittwoch",
    "guide.weekday.thu": "Donnerstag",
    "guide.weekday.fri": "Freitag",
    "guide.weekday.sat": "Samstag",
    "guide.weekday.sun": "Sonntag",
    "guide.openingHours.closed": "Geschlossen",
    "guide.website.headline": "Webseite",
    "guide.navigate.headline": "Navigieren",
    "guide.welcomeTitle": "Willkommen bei {name}",
    "guide.closedToday": "Wir haben heute geschlossen",
    "guide.closingAt": "Wir schließen heute um {time} Uhr",
    "guide.start.title": "Willkommen bei luca",
    "guide.start.cta": "Location finden",
    "guide.start.description": "Wenn du spannende Locations in deiner Umgebung finden willst, bist du hier genau richtig: Klicke auf \"Location finden\" und entdecke deine Umgebung neu!",
    "guide.contactSection.title": "Kontaktiere uns",
    "hashtags.SPANISH": "Spanisch",
    "hashtags.GERMAN": "Deutsch",
    "hashtags.KOREAN": "Koreanisch",
    "hashtags.AUSTRALIAN": "Australisch",
    "hashtags.MEXICAN": "Mexikanisch",
    "hashtags.GREEK": "Griechisch",
    "hashtags.FILIPINO": "Philippinisch",
    "hashtags.INDIAN": "Indisch",
    "hashtags.ITALIAN": "Italienisch",
    "hashtags.CHINESE": "Chinesisch",
    "hashtags.AMERICAN": "Amerikanisch",
    "hashtags.ASIAN": "Asiatisch",
    "hashtags.EUROPEAN": "Europäisch",
    "hashtags.ETHIOPIAN": "Äthiopisch",
    "hashtags.COLOMBIAN": "Kolumbianisch",
    "hashtags.BRAZILIAN": "Brasilianisch",
    "hashtags.PORTUGESE": "Portugiesisch",
    "hashtags.MEDITERRANEAN": "Mediterran",
    "hashtags.THAI": "Thai",
    "hashtags.FRENCH": "Französisch",
    "hashtags.JAPANESE": "Japanisch",
    "hashtags.LEBANESE": "Libanesisch",
    "hashtags.SOUTH_AFRICAN": "Südafrikanisch",
    "hashtags.HALAL": "Halal",
    "hashtags.VEGETARIAN_FRIENDLY": "Vegetarisch",
    "hashtags.VEGAN_OPTIONS": "Vegan",
    "hashtags.COFFEE": "Kaffee",
    "hashtags.DESSERT": "Dessert",
    "guide.open": "Offen",
    "guide.call": "Anrufen",
    "guide.follow": "Folge uns",
    "guide.locationPermission": "Bitte erlaube uns, auf deinen Standort zuzugreifen.",
    "guide.more": "Mehr",
    "menu.title": "Menü",
    "menu.productInfo": "Produktinformationen",
    "menu. allergens": "Allergene",
    "openingHours.weekday.openAt": "Wir öffnen um {time} Uhr.",
    "openingHours.weekday.closedAt": "Wir schließen um {time} Uhr.",
    "openingHours.weekday.closedToday": "Wir haben heute geschlossen und öffnen wieder am {weekday} ab {time}.",
    "guide.bookTable": "Tisch reservieren",
    "reservations.reserveSlot.overview.dateTime": "Datum und Uhrzeit",
    "reservations.maxGuestCount.exceeded": "Dieser Standort ermöglicht Online-Reservierungen für bis zu {maxGuestCount} Gäste. Für größere Gruppen kannst du die Location direkt kontaktieren.",
    "reservations.noShiftsAvailable": "Keine Optionen gefunden.{br}Bitte wähle einen anderen Tag.",
    "discovery.loadMore": "Mehr",
    "hashtags.ISRAELI": "Israelisch",
    "hashtags.INTERNATIONAL": "International",
    "hashtags.PERUVIAN": "Peruanisch",
    "hashtags.VIETNAMESE": "Vietnamesisch",
    "hashtags.STEAK": "Steak",
    "hashtags.BURGER": "Burger",
    "hashtags.BAR": "Bar",
    "hashtags.KOSHER": "Koscher",
    "hashtags.GLUTEN_FREE": "Glutenfrei",
    "reservations.reserveSlots.submit.request": "Tisch anfragen",
    "reservations.agreements.request": "Durch das Absenden deiner Reservierungsanfrage stimmst du den {termsAndConditionsLink} zu. Informationen zur Verwendung deiner Daten findest du in unseren {privacyPolicyLink}.",
    "reservations.reserveSuccess.description.request": "Sobald deine Reservierungsanfrage bearbeitet wurde, werden wir dich benachrichtigen.",
    "reservations.reserveSuccess.title.request": "Deine Reservierungsanfrage wurde abgeschickt",
    "reservations.errorNotification.noSlotsAvailable.generic": "Leider sind zur gesuchten Zeit keine freien Tische verfügbar. Bitte wähle eine andere Uhrzeit aus und suche nochmal.",
    "widget.button.title": "Jetzt reservieren",
    "discovery.discoveryView.title": "Entdecken",
    "reservations.fee.pending": "Führe die Bezahlung durch, um deine Reservierung abzuschließen.",
    "reservations.fee.description": "Deine Bezahlung ist noch in Bearbeitung.",
    "reservations.fee.button": "Zur Bezahlung",
    "generic.continue": "Weiter",
    "reservations.fee.notification": "Dieses Restaurant erhebt eine No-Show-Gebühr für den Fall, dass du nicht erscheinst oder kurzfristig stornierst ({cancellationFeePerPerson} pro Gast). Die Gebühr wird dir automatisch zurückerstattet, sobald deine Reservierung abgeschlossen ist, oder du bis zu 24 Stunden im Voraus stornierst.",
    "generic.loading": "Lädt ...",
    "generic.schema.invalidCharacters": "Der eingegebene Wert enthält ungültige Zeichen.",
    "generic.schema.lengthError": "Der eingegebene Wert ist zu lang (maximal {max} Zeichen).",
    "generic.contact.phone": "Telefonnummer",
    "reservations.reserveSlots.contactDirectly.title": "Kontaktiere {locationName}",
    "reservations.reserveSlots.contactDirectly.message": "{locationName} benötigt mehr Vorlaufzeit, um Online-Reservierungen anzunehmen. Rufe gern bei {locationName} an, um Tischverfügbarkeiten abzufragen!",
    "reservations.reserveSlots.contactDirectly.cta": "Location anrufen",
    "reservations.reserveSlots.availableTimeSlots.earlier": "Früher",
    "reservations.noncapture.fee.notification": "Dieses Restaurant erhebt eine No-Show-Gebühr von {cancellationFeePerPerson} pro Gast, falls du nicht erscheinst oder kurzfristig stornierst. Die Gebühr wird nicht abgebucht, wenn du deine Reservierung wahrnimmst oder bis spätestens [cancellationDateTime] stornierst."
}