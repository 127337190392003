import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { PrimaryButton } from 'components/general';
import { HostUrlContainer, ReservationsContainer } from 'containers';
import { LocationGroupReservationsEnabledAndConfiguredType } from 'types';
import { getFormattedTime } from 'utils';

import { ReservationsContactInfoPopup } from '../ReservationsContactInfoPopup';
import {
  StyledResultWrapper,
  StyledTitle,
  StyledInfo,
  StyledTextButton,
} from './AvailableTimeSlots.styled';
import { getApplicableShiftWithFee } from '../ReservationsModal.helper';

type PropsType = {
  availableTimeSlots: number[];
  locationGroup: LocationGroupReservationsEnabledAndConfiguredType;
};
export const AvailableTimeSlots = ({
  availableTimeSlots,
  locationGroup,
}: PropsType) => {
  const intl = useIntl();

  const { hostUrl } = HostUrlContainer.useContainer();

  const {
    preferredTime,
    setSelectedReservationTimestamp,
    isEarliestSearchOption,
    selectedGuestCount,
  } = ReservationsContainer.useContainer();

  const {
    discoverId,
    name,
    publicPhone,
    reservationConfig: { showLeadtimeConfirmationMessage },
    shifts,
  } = locationGroup;

  const [
    isReservationContactInfoPopupOpen,
    setIsReservationContactInfoPopupOpen,
  ] = useState(false);

  const nearestAvailableTimeSlots = useMemo(() => {
    const sortedNearestTimeSlots = availableTimeSlots.sort((a, b) => {
      const distanceA = Math.abs(a - preferredTime);
      const distanceB = Math.abs(b - preferredTime);
      if (distanceA === distanceB) {
        return a - b;
      }
      return distanceA - distanceB;
    });

    return sortedNearestTimeSlots.slice(0, 4).sort((a, b) => a - b);
  }, [availableTimeSlots, preferredTime]);

  const toggleReservationContactInfoPopup = () => {
    setIsReservationContactInfoPopupOpen(!isReservationContactInfoPopupOpen);
  };

  const handleOnClickTimeSlot = (reservationTimestamp: number) => {
    const applicableShiftWithFee = getApplicableShiftWithFee(
      reservationTimestamp,
      selectedGuestCount,
      shifts
    );

    if (applicableShiftWithFee) {
      const reservationData = JSON.stringify({
        reservationTimestamp,
        guestCount: selectedGuestCount,
      });
      const encodedReservationData = encodeURIComponent(reservationData);
      const discoveryReservationUrl =
        'Android' in window
          ? `${hostUrl}/discovery/${discoverId}`
          : `${hostUrl}/discovery/${discoverId}?reservationData=${encodedReservationData}`;
      window.open(discoveryReservationUrl, '_blank');
    } else {
      setSelectedReservationTimestamp(reservationTimestamp);
    }
  };

  if (availableTimeSlots.length === 0) {
    return (
      <StyledInfo>
        {intl.formatMessage({
          id: 'reservations.errorNotification.noSlotsAvailable.generic',
        })}
      </StyledInfo>
    );
  }

  return (
    <>
      <StyledTitle>
        {intl.formatMessage({
          id: 'reservations.chooseTime',
        })}
      </StyledTitle>

      <StyledResultWrapper>
        {showLeadtimeConfirmationMessage && isEarliestSearchOption && (
          <StyledTextButton
            onClick={toggleReservationContactInfoPopup}
            data-cy="reservation-available-earlier"
          >
            {intl.formatMessage({
              id: 'reservations.reserveSlots.availableTimeSlots.earlier',
            })}
          </StyledTextButton>
        )}

        {nearestAvailableTimeSlots.map(timeSlot => (
          <PrimaryButton
            key={timeSlot}
            onClick={() => handleOnClickTimeSlot(timeSlot)}
          >
            {getFormattedTime(timeSlot)}
          </PrimaryButton>
        ))}
      </StyledResultWrapper>

      <ReservationsContactInfoPopup
        isVisible={isReservationContactInfoPopupOpen}
        locationName={name}
        publicPhoneNumber={publicPhone}
        closePopup={toggleReservationContactInfoPopup}
      />
    </>
  );
};
