import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const StyledInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledTerm = styled.span`
  margin-right: 24px;
`;

export const StyledValue = styled.span`
  font-weight: 600;
  word-break: break-word;
`;
