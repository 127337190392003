import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { createRoot } from 'react-dom/client';

import { widgetContainerId } from 'constants/widgetIdentifiers';
import { usePortal } from 'hooks';

import { HostUrlContainer } from 'containers';
import { App } from './App';

const lucaWidgetRootContainer = document.getElementById(
  widgetContainerId
) as HTMLElement;

const LucaReservationsWidget = () => {
  const widgetContainer = usePortal();
  const discoverId = useMemo(
    () => lucaWidgetRootContainer.dataset.discoverId,
    []
  );
  const hideDefaultButton = useMemo(
    () => lucaWidgetRootContainer.dataset.hideDefaultButton === 'true',
    []
  );

  return createPortal(
    <HostUrlContainer.Provider
      initialState={lucaWidgetRootContainer.dataset.environment}
    >
      <App discoverId={discoverId} hideDefaultButton={hideDefaultButton} />
    </HostUrlContainer.Provider>,

    widgetContainer
  );
};

createRoot(lucaWidgetRootContainer).render(<LucaReservationsWidget />);
