import { useIntl } from 'react-intl';

import yup from 'utils/yupConfig';

export const useReserveSlotSchema = () => {
  const intl = useIntl();

  return yup.object().shape({
    fullName: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'reservations.reserveSlots.form.fullNameError',
        })
      )
      .safeString(
        intl.formatMessage({ id: 'generic.schema.invalidCharacters' })
      )
      .max(
        255,
        intl.formatMessage({ id: 'generic.schema.lengthError' }, { max: 255 })
      ),
    phone: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'reservations.reserveSlots.form.phone.required',
        })
      )
      .phoneNumber(
        intl.formatMessage({
          id: 'reservations.reserveSlots.form.phone.format',
        })
      ),
    email: yup
      .string()
      .required(
        intl.formatMessage({ id: 'reservations.reserveSlots.form.emailError' })
      )
      .zodAlignedEmail(
        intl.formatMessage({
          id: 'reservations.reserveSlots.form.invalidEmailError',
        })
      ),
    message: yup
      .string()
      .max(
        255,
        intl.formatMessage({ id: 'generic.schema.lengthError' }, { max: 255 })
      )
      .optional(),
  });
};
