import { useState } from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { ThemeProvider } from 'styled-components';
import { useMountEffect } from '@react-hookz/web';
import {
  ConfigProvider as AntdMobileConfigProvider,
  setDefaultConfig,
} from 'antd-mobile';
import enUS from 'antd-mobile/es/locales/en-US';
import deDE from 'constants/antdLocales/de';

import { GlobalStyle } from 'components/GlobalStyle';
import { PoweredByLucaLogoLink } from 'constants/links';
import { HostUrlContainer, ReservationsContainer } from 'containers';

// Fallback css for iOS version <10
// import 'antd-mobile/bundle/css-vars-patch.css';
// Currently only matching supported locale from antd-mobile v5

import { getOpenTimeSlots } from 'network';
import {
  CustomThemeType,
  LocationGroupReservationsEnabledAndConfiguredType,
} from 'types';
import {
  withLocationGroup,
  getLanguage,
  // MATOMO_BASE_URL,
  // MATOMO_SITE_ID,
} from 'utils';

import { AvailableTimeSlots } from './AvailableTimeSlots';
import { ReservationsInitialForm } from './InitialForm';
import { ReserveSlotForm } from './ReserveSlotForm';
import {
  getTimeRangeForTimeSlot,
  useAggregatedShifts,
} from './ReservationsModal.helper';
import {
  StyledCloseOutlined,
  ModalContentWrapper,
  StyledPoweredByLucaLogo,
} from './ReservationsModal.styled';

// import 'normalize.css';

import 'moment/locale/en-gb';
import 'moment/locale/de';

setDefaultConfig({
  locale: enUS,
});

const TIMEZONE = 'Europe/Berlin';
momentTimezone.tz(TIMEZONE);

const locale = getLanguage();
moment.locale(locale);

type PropertiesType = {
  locationGroup: LocationGroupReservationsEnabledAndConfiguredType;
  theme: CustomThemeType;
  closeWidget: () => void;
};
const RawReservationsModalContent = ({
  locationGroup,
  theme,
  closeWidget,
}: PropertiesType) => {
  const {
    discoverId,
    maxGuestCount,
    shifts,
    reservationConfig: {
      minLeadTime,
      maxLeadTime,
      requireConfirmation,
      turnTime,
    },
  } = locationGroup;

  const { hostUrl } = HostUrlContainer.useContainer();
  const { preferredTime, selectedGuestCount, selectedReservationTimestamp } =
    ReservationsContainer.useContainer();

  const [availableTimeSlots, setAvailableTimeSlots] = useState<number[] | null>(
    null
  );

  const aggregatedShifts = useAggregatedShifts(shifts);

  useMountEffect(async () => {
    try {
      const { earliestStartingTime, latestStartingTime } =
        getTimeRangeForTimeSlot({
          unixTimestamp: preferredTime,
          minLeadTime,
          maxLeadTime,
          turnTime,
        });

      const { openReservationTimestamps } = await getOpenTimeSlots(
        hostUrl,
        discoverId,
        {
          earliestStartingTime,
          latestStartingTime,
          guestCount: selectedGuestCount,
        }
      );

      if (openReservationTimestamps.length > 0) {
        setAvailableTimeSlots(openReservationTimestamps);
      }
    } catch (error_) {
      console.error(error_);
    }
  });

  return (
    // TODO: Provide german locales necessary if necessary for a component.
    // Currently german is not supported / existent by antd-mobile
    // See: https://mobile.ant.design/guide/i18n
    <AntdMobileConfigProvider locale={getLanguage() === 'de' ? deDE : enUS}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ModalContentWrapper>
          <StyledCloseOutlined onClick={closeWidget} />
          {typeof selectedReservationTimestamp === 'number' ? (
            <ReserveSlotForm
              discoverId={discoverId}
              locationGroupName={locationGroup.name}
              reservationTimestamp={selectedReservationTimestamp}
              requireConfirmation={requireConfirmation}
            />
          ) : (
            <>
              <ReservationsInitialForm
                aggregatedShifts={aggregatedShifts}
                locationGroup={locationGroup}
                setAvailableTimeSlots={setAvailableTimeSlots}
              />
              {selectedGuestCount <= maxGuestCount &&
                Array.isArray(availableTimeSlots) && (
                  <AvailableTimeSlots
                    availableTimeSlots={availableTimeSlots}
                    locationGroup={locationGroup}
                  />
                )}
            </>
          )}

          <StyledPoweredByLucaLogo src={PoweredByLucaLogoLink} />
        </ModalContentWrapper>
      </ThemeProvider>
    </AntdMobileConfigProvider>
  );
};

export const ReservationsModalContent = withLocationGroup<{
  discoverId: string;
  closeWidget: () => void;
}>(RawReservationsModalContent);
