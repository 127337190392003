import { useCallback, useState } from 'react';
import moment from 'moment';
import { createContainer } from 'unstated-next';
import { getNearestFutureMinutes } from 'utils';

const DEFAULT_GUEST_COUNT = 2;

const useReservationsContainer = () => {
  const [preferredTime, setPreferredTime] = useState(
    getNearestFutureMinutes(moment(), 15)
  );
  const [selectedGuestCount, setSelectedGuestCount] =
    useState(DEFAULT_GUEST_COUNT);
  const [selectedReservationTimestamp, setSelectedReservationTimestamp] =
    useState<number | null>(null);

  const resetReservation = useCallback(
    () => setSelectedReservationTimestamp(null),
    []
  );

  const [isEarliestSearchOption, setIsEarliestSearchOption] = useState(true);

  return {
    preferredTime,
    setPreferredTime,
    selectedGuestCount,
    setSelectedGuestCount,
    selectedReservationTimestamp,
    setSelectedReservationTimestamp,
    resetReservation,
    isEarliestSearchOption,
    setIsEarliestSearchOption,
  };
};

export const ReservationsContainer = createContainer(useReservationsContainer);
