import { useQuery } from 'react-query';

import { LocationGroupThemeSettingsType } from 'types';

import { QUERY_KEYS } from '../constants';
import { headers } from '../headers';
import { getRequest } from '../utils';

const getThemeByDiscoverId = (
  hostUrl: string,
  discoverId: string
): Promise<LocationGroupThemeSettingsType> =>
  getRequest(
    `${hostUrl}/pay/api/v3/public/discover/${discoverId}/themeAndPaymentSettings`,
    headers
  );

export const useGetThemeByDiscoverId = (hostUrl: string, discoverId: string) =>
  useQuery([QUERY_KEYS.THEME, discoverId], () =>
    getThemeByDiscoverId(hostUrl, discoverId)
  );
