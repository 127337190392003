import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  /* Set antd-mobile styles, reference: https://mobile.ant.design/guide/theming */
  :root:root {
    --adm-font-family: sans-serif;
    --adm-font-size-main: 14px;

    --adm-color-primary: #7d96ff;
    --adm-color-success: rgb(178, 197, 150);
    --adm-color-warning: rgb(253, 172, 114);
    --adm-color-danger: #ff3141;

    --adm-color-white: #ffffff;
    --adm-color-text: #000000;
    --adm-color-text-secondary: #666666;
    --adm-color-weak: #999999;
    --adm-color-light: #cccccc;
    --adm-color-border: #7d96ff;
    --adm-color-box: #ffffff
    --adm-color-background: #ffffff;

    --adm-font-size-5: 14px;
    --adm-font-size-6: 14px;
    --adm-font-size-7: 14px;
    --adm-font-size-8: 14px;

    --adm-mask-z-index: 10000;
    --adm-popup-z-index: 10000;
  }

  body {
    font-family: sans-serif;
    font-size: 14px;
  }

  .adm-picker-view-mask-middle {
    border: 1px solid var(--adm-color-border);
    border-radius: 6px;
  }

  /* toastMaskClassName */
  .luca-toast-mask {
    position: absolute;

    .adm-toast-wrap {
      position: absolute;

      .adm-toast-main{
        top: 42px !important;
        transform: translateX(-50%);
        max-width: 90%;
        background-color: rgba(0, 0, 0, 0.87);
        text-align: center;
        word-break: break-word;
      }
    }
  }
`;
