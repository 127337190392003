import styled from 'styled-components';
import { Ellipsis } from 'antd-mobile';
import { PhoneOutlined } from '@ant-design/icons';

import { PrimaryButton } from 'components/general';

export const StyledName = styled(Ellipsis)`
  font-size: 16px;
  font-weight: bold;
`;

export const StyledPhoneOutlined = styled(PhoneOutlined)`
  font-size: 24px;
`;

export const GuestCountLimitInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
  word-break: break-word;
`;

export const NavigationPlaceholder = styled.div`
  width: 22px;
  height: 22px;
`;

export const TimeValue = styled.div`
  height: 34px;
  border: 1px solid #7d96ff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 24px 0;

  &:first-child {
    margin-top: 0px;
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  margin: 24px 0;
`;

export const buttonIconStyle = {
  fontSize: '20px',
  marginRight: '12px',
};
