import { PrimaryButton } from 'components/general';
import styled from 'styled-components';

export const StyledTitle = styled.div`
  font-weight: 600;
  margin-bottom: 24px;
`;

export const StyledInfo = styled.div`
  font-weight: 600;
  margin-bottom: 24px;
  padding-bottom: 24px;
`;

export const StyledResultWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-bottom: 24px;
  padding-bottom: 24px;
`;

export const StyledTextButton = styled(PrimaryButton)`
  padding-left: 0;
  padding-right: 0;
`;
