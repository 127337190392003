import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  margin: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ErrorHeadline = styled.div`
  margin-top: 32px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
`;
