import React from 'react';
import { useDeepCompareEffect } from '@react-hookz/web';

import { TimePickerSelect } from './MobileTimePicker.styled';

type PropertiesType = {
  selectedTime: number;
  timePickerValues: {
    label: string;
    value: number;
  }[];
  onChange: (time: number) => void;
};
export const MobileTimePicker = ({
  selectedTime,
  timePickerValues,
  onChange,
}: PropertiesType) => {
  useDeepCompareEffect(() => {
    const timeExists = timePickerValues.some(
      item => item.value === selectedTime
    );

    if (!timeExists) {
      const closestTime = timePickerValues.reduce((prev, curr) =>
        Math.abs(curr.value - selectedTime) <
        Math.abs(prev.value - selectedTime)
          ? curr
          : prev
      );

      onChange(closestTime.value);
    }
  }, [timePickerValues, selectedTime, onChange]);

  const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newTimeValue = parseInt(e.target.value, 10);
    onChange(newTimeValue);
  };

  return (
    <TimePickerSelect value={selectedTime} onChange={handleTimeChange}>
      {timePickerValues.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </TimePickerSelect>
  );
};
