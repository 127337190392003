import { useQuery } from 'react-query';

import { LocationGroupDiscoveryType } from 'types';

import { QUERY_KEYS } from '../constants';
import { headers } from '../headers';
import { getRequest } from '../utils';

const getLocationGroup = (
  hostUrl: string,
  discoverId: string
): Promise<LocationGroupDiscoveryType> =>
  getRequest(
    `${hostUrl}/api/v4/locationGroups/discover/${discoverId}`,
    headers
  );

export const useGetLocationGroup = (hostUrl: string, discoverId: string) =>
  useQuery([QUERY_KEYS.LOCATION_GROUP, discoverId], () =>
    getLocationGroup(hostUrl, discoverId)
  );
