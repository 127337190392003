import { useIntl } from 'react-intl';

import { errorIconPath } from 'assets/icons';

import { ErrorHeadline, ErrorWrapper } from './NotFound.styled';

export const NotFound = () => {
  const intl = useIntl();

  return (
    <ErrorWrapper>
      <img alt="error-icon" src={errorIconPath} />
      <ErrorHeadline>
        {intl.formatMessage({ id: 'error.place.notFound.headline' })}
      </ErrorHeadline>
      <div>
        {intl.formatMessage({ id: 'error.place.notFound.description' })}
      </div>
    </ErrorWrapper>
  );
};
