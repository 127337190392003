import styled from 'styled-components';
import { Button } from 'antd-mobile';

import { colors } from 'constants/globalStyles';

export const primaryStyle = {
  mainBorder: colors.blue,
  mainBackground: colors.blue,
  focusBorder: colors.light_blue_2,
  focusBackground: colors.light_blue_2,
  hoverBorder: colors.light_blue_2,
  hoverBackground: colors.light_blue_2,
  disabledBorder: colors.light_blue_3,
  disabledBackground: colors.light_blue_3,
};

export const whiteStyle = {
  mainBorder: colors.light_blue_1,
  mainBackground: colors.white,
  focusBorder: colors.light_blue_2,
  focusBackground: colors.light_blue_2,
  hoverColor: colors.white,
  hoverBorder: colors.light_blue_2,
  hoverBackground: colors.light_blue_1,
  disabledBorder: colors.light_blue_2,
  disabledBackground: colors.light_blue_2,
};

const ButtonStructure = styled(Button)`
  font-weight: bold;
  text-transform: uppercase;
  font-size: var(--adm-font-size-main);
`;

const BasicButton = styled(ButtonStructure)`
  padding: 10px 24px;
  border-radius: 24px;
`;

interface ButtonStyleType {
  mainBorder: string;
  mainBackground: string;
  focusBorder: string;
  focusBackground: string;
  // TODO: Rework button styles to also accept color for all states
  hoverColor?: string;
  hoverBorder: string;
  hoverBackground: string;
  disabledBorder: string;
  disabledBackground: string;
}

const createButton = (buttonStyle: ButtonStyleType) => styled(BasicButton)`
  border: 2px solid ${buttonStyle.mainBorder} !important;
  background: ${buttonStyle.mainBackground} !important;

  &:focus {
    color: ${colors.dark_black} !important;
    background: ${buttonStyle.focusBackground} !important;
    border-color: ${buttonStyle.focusBorder} !important;
  }

  &:hover {
    color: ${buttonStyle.hoverColor || colors.dark_black} !important;
    background: ${buttonStyle.hoverBackground} !important;
    border-color: ${buttonStyle.hoverBorder} !important;
  }

  &:disabled {
    /* color: ${colors.light_black} !important; */
    background: ${buttonStyle.disabledBackground} !important;
    border-color: ${buttonStyle.disabledBorder} !important;
    cursor: no-drop;
  }
`;

export const PrimaryButton = createButton(primaryStyle);
export const WhiteButton = createButton(whiteStyle);

export const TextButton = styled(ButtonStructure).attrs({
  fill: 'none',
})`
  color: var(--adm-color-primary);
  padding: 0;
`;
