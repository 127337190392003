import styled from 'styled-components';
import { CapsuleTabs } from 'antd-mobile';

export const StyledCapsuleTabs = styled(CapsuleTabs)`
  --adm-color-primary: #d7deff;

  .adm-capsule-tabs-header {
    margin-bottom: 24px;
    padding: 0 12px 0 0;
    border: none;
  }

  .adm-capsule-tabs-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 0;
    color: black;

    border-radius: 50%;
    border-style: solid;
    border-color: #7d96ff;
    border-width: 1px;
  }

  .adm-capsule-tabs-tab-active {
    font-weight: bold;
    border-width: 2px;
  }
`;
