import styled from 'styled-components';

import { LeftOutlined } from '@ant-design/icons';
import { TextButton } from 'components/general';

export const StyledTextButton = styled(TextButton)`
  margin-bottom: 24px;

  > span {
    display: flex;
  }
`;

export const StyledLeftOutlinedIcon = styled(LeftOutlined)`
  display: flex;
  align-self: center;
  margin-right: 8px;
`;
