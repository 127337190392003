import { PropsWithChildren, ReactNode, useContext } from 'react';
import Frame, { FrameContext } from 'react-frame-component';
import { StyleSheetManager } from 'styled-components';

const iFrameStyles = {
  border: 0,
  width: '100%',
  height: '100%',
  fontFamily: 'GT-Walsheim-Regular, sans-serif',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.4)',
};
/* <link href="https://app.luca-app.de/reservations-widget/index.css" rel="stylesheet" /> */

const initialContent = `<!DOCTYPE html><html><head>
<link href="https://app.luca-app.de/reservations-widget/index.css" rel="stylesheet" />
</head><body><div></div></body></html>`;

// Based on https://stackoverflow.com/questions/66556752/styled-components-are-not-rendering-in-react-iframe/66557426#66557426

const InjectFrameStyles = ({ children }: PropsWithChildren<unknown>) => {
  const { document } = useContext(FrameContext);
  return (
    <StyleSheetManager target={document?.head}>{children}</StyleSheetManager>
  );
};

type StyledFramePropsType = {
  children: ReactNode;
  innerRef: any; // https://github.com/ryanseddon/react-frame-component/issues/225
};
export const StyledFrame = ({ children, innerRef }: StyledFramePropsType) => {
  return (
    <Frame ref={innerRef} initialContent={initialContent} style={iFrameStyles}>
      <InjectFrameStyles>{children}</InjectFrameStyles>
    </Frame>
  );
};
