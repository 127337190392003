import styled from 'styled-components';

import { PrimaryButton } from 'components/general';

export const Title = styled.div`
  color: var(--adm-color-text);
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 16px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  &.ant-btn {
    line-height: 16px;
    padding-top: 14px !important;
  }
`;

export const StyledHighlight = styled.div`
  font-weight: bold;
  padding: 16px 0 32px;
`;

export const PopupContainer = styled.div`
  padding: 24px 16px 32px;
`;
